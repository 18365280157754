import { Suspense, lazy, ElementType } from 'react';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const JoinSuccessPage = Loadable(lazy(() => import('../pages/auth/JoinSuccessPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));

export const HomePage = Loadable(lazy(() => import('../pages/home/HomePage/HomePage')));
export const AuctionPricePage = Loadable(lazy(() => import('../pages/home/AuctionPricePage')));

export const AlarmPage = Loadable(lazy(() => import('../pages/home/AlarmPage')));
export const SettingPage = Loadable(lazy(() => import('../pages/home/setting/SettingPage')));
export const IntroducePage = Loadable(lazy(() => import('../pages/home/setting/IntroducePage')));
export const PushAlertPage = Loadable(lazy(() => import('../pages/home/setting/PushAlertPage')));
export const TermPage = Loadable(lazy(() => import('../pages/home/setting/term/TermPage')));
export const ServiceTermPage = Loadable(lazy(() => import('../pages/home/setting/term/ServiceTermPage')));
export const PersonalInfoTermPage = Loadable(lazy(() => import('../pages/home/setting/term/PersonalInfoTermPage')));
export const ParticipantTermPage = Loadable(lazy(() => import('../pages/home/setting/term/ParticipantTermPage')));
export const MarketingTermPage = Loadable(lazy(() => import('../pages/home/setting/term/MarketingTermPage')));

export const WithdrawPage = Loadable(lazy(() => import('../pages/home/setting/WithdrawPage')));

export const FundingPage = Loadable(lazy(() => import('../pages/funding/FundingPage/FundingPage')));
export const FundingDetailPage = Loadable(lazy(() => import('../pages/funding/FundingDetailPage')));

export const FundingRegister1Page = Loadable(lazy(() => import('../pages/funding/FundingRegister1Page')));
export const FundingRegister1SubPage = Loadable(lazy(() => import('../pages/funding/FundingRegister1SubPage')));
export const FundingRegister2Page = Loadable(lazy(() => import('../pages/funding/FundingRegister2Page')));
export const FundingRegister3Page = Loadable(lazy(() => import('../pages/funding/FundingRegister3Page')));
export const FundingRegister4Page = Loadable(lazy(() => import('../pages/funding/FundingRegister4Page')));

export const PigPage = Loadable(lazy(() => import('../pages/pig/PigPage')));
export const PigDetailPage = Loadable(lazy(() => import('../pages/pig/PigDetailPage')));
export const PigRegisterPage = Loadable(lazy(() => import('../pages/pig/PigRegisterPage')));

export const FarmPage = Loadable(lazy(() => import('../pages/farm/FarmPage')));
export const FarmDetailPage = Loadable(lazy(() => import('../pages/farm/FarmDetailPage')));
export const FarmRegisterPage = Loadable(lazy(() => import('../pages/farm/FarmRegisterPage')));
export const FarmVerifyBizPage = Loadable(lazy(() => import('../pages/farm/FarmVerifyBizPage')));
export const FarmCCTV = Loadable(lazy(() => import('../pages/monitor/CameraViewer')));
export const FarmUnity = Loadable(lazy(() => import('../pages/monitor/UnityViewer')));

export const StorePage = Loadable(lazy(() => import('../pages/store/StorePage')));
export const StoreOrderPage = Loadable(lazy(() => import('../pages/store/StoreOrderPage')));
export const StoreOrderDetailPage = Loadable(lazy(() => import('../pages/store/StoreOrderDetailPage')));
export const StoreDeliveryPage = Loadable(lazy(() => import('../pages/store/StoreDeliveryPage')));
export const StoreDeliveryDetailPage = Loadable(lazy(() => import('../pages/store/StoreDeliveryDetailPage')));
export const StoreReturnPage = Loadable(lazy(() => import('../pages/store/StoreReturnPage')));
export const StoreReturnDetailPage = Loadable(lazy(() => import('../pages/store/StoreReturnDetailPage')));
export const StoreFeedbackPage = Loadable(lazy(() => import('../pages/store/StoreFeedbackPage')));

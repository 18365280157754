import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storageSession from "redux-persist/lib/storage/session";

import { FarmerReducer } from "./FarmerReducer";

// config 작성
const persistConfig = {
    key: "root", // localStorage key 
    storage: storageSession, // localStorage
    whitelist: [ "farmer"], // target (reducer name)
}

const rootReducer = combineReducers({
    farmer: FarmerReducer
});

export default persistReducer(persistConfig, rootReducer);
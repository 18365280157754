// @mui
import { Box, BoxProps } from '@mui/material';


export default function Main({ children, ...other }: BoxProps) {
  
  return (
    <Box
      component="main"
      {...other}
    >
      {children}
    </Box>
  );
}

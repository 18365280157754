import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, NavLink as RouterLink } from 'react-router-dom';

// @mui
import { Link } from '@mui/material';
import Main from './Main';
import Nav from './Nav';


export default function MainLayout() {
    const location = useLocation();

    return (
        <>
            <div className="layout-wrap">
                <Main>
                    <Outlet />
                </Main>
                <Nav />
            </div>
        </>
    )
}

export interface Farmer {
    FARMER_ID: number;
    FARMER_NM: string;
    KAKAO_ID: number;
    KAKAO_NICK: string;
    EMAIL: string;
    PLAN_ID: number;
    FARMER_BIZ: string;
    MOBILE: string;
    USE_YN: string;
    CREATE_DT: string;
    UPDATE_DT: string;
    CAREER: string;
    HELLO: string;
}

export interface FarmerAction{
    type: string;
    payload: Farmer[] | null;
}

export const FarmerReducer = (state: Farmer[] | null = null, action: FarmerAction) : Farmer[] | null => {
    switch (action.type) {
        case 'SET_FARMER':
            return action.payload;
        default:
            return state;
    }
}
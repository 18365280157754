import Router from './routes';

import './App.css';
import './assets/css/default.css';
import './assets/css/font.css';
import './assets/css/custom.css';
import './assets/css/swiper-bundle.min.css';
import 'react-tooltip/dist/react-tooltip.css';

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;

import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, NavLink as RouterLink } from 'react-router-dom';

// @mui
import { Link } from '@mui/material';


export default function Nav() {
    const location = useLocation();

    const [currentPage , setCurrentPage] = useState<string>(location.pathname.split("/")[1]);
    const [deactive, setDeactive] = useState<boolean>(false);

    useEffect(() => {
        setCurrentPage(location.pathname.split("/")[1]);
        HideIfContains(location.pathname);

    }, [location.pathname]);

    return (
        deactive
        ? <></> :
            <nav>
                <ul>
                    <li>
                        <Link
                            key="Home"
                            noWrap
                            underline="none"
                            component={RouterLink}
                            to="/home"
                        >
                            <div className={currentPage === "home" ? "menu1 active" : "menu1"}>홈</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            key="Funding"
                            noWrap
                            underline="none"
                            component={RouterLink}
                            to="/funding"
                        >
                            <div className={currentPage === "funding" ? "menu2 active" : "menu2"}>펀딩</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            key="Pig"
                            noWrap
                            underline="none"
                            component={RouterLink}
                            to="/pig"
                        >
                            <div className={currentPage === "pig" ? "menu3 active" : "menu3"}>돼지</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            key="Farm"
                            noWrap
                            underline="none"
                            component={RouterLink}
                            to="/farm"
                        >
                            <div className={currentPage === "farm" ? "menu4 active" : "menu4"}>농장</div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            key="Store"
                            noWrap
                            underline="none"
                            component={RouterLink}
                            to="/store"
                        >
                            <div className={currentPage === "store" ? "menu5 active" : "menu5"}>스토어</div>
                        </Link>
                    </li>
                </ul>
            </nav>
    )

    function HideIfContains(path: string) {
        if (path.includes("setting")
            || path.includes("register")
            || path.includes("orderManage")
            || path.includes("unity")
            || path.includes("cctv")) {
            setDeactive(true);
        }
        else {
            setDeactive(false);
        }
    }
}
import path from "path";
import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main/MainLayout";

import {
  LoginPage,
  RegisterPage,
  JoinSuccessPage,
  VerifyCodePage,
  HomePage,
  AuctionPricePage,
  AlarmPage,
  SettingPage,
  IntroducePage,
  PushAlertPage,
  TermPage,
  ServiceTermPage,
  PersonalInfoTermPage,
  ParticipantTermPage,
  MarketingTermPage,
  WithdrawPage,
  FundingPage,
  FundingDetailPage,
  FundingRegister1Page,
  FundingRegister1SubPage,
  FundingRegister2Page,
  FundingRegister3Page,
  FundingRegister4Page,
  PigPage,
  PigDetailPage,
  PigRegisterPage,
  FarmPage,
  FarmDetailPage,
  FarmRegisterPage,
  FarmVerifyBizPage,
  FarmCCTV,
  FarmUnity,
  StorePage,
  StoreOrderPage,
  StoreOrderDetailPage,
  StoreDeliveryPage,
  StoreDeliveryDetailPage,
  StoreReturnPage,
  StoreReturnDetailPage,
  StoreFeedbackPage,
} from "./elements";
import BizenrollPage from "../pages/publicapi/BizenrollPage";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/auth/login" replace />,
      index: true,
    },
    {
      path: "auth",
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <RegisterPage /> },
        { path: "join-success", element: <JoinSuccessPage /> },
        { path: "verify-code", element: <VerifyCodePage /> },
      ],
    },

    {
      path: "",
      element: <MainLayout />,
      children: [
        { element: <Navigate to={"/home"} replace />, index: true },
        {
          path: "home",
          children: [
            { path: "", element: <HomePage /> },
            { path: "auctionPrice", element: <AuctionPricePage /> },
            { path: "alarm", element: <AlarmPage /> },
            {
              path: "setting",
              children: [
                { path: "", element: <SettingPage /> },
                { path: "introduce", element: <IntroducePage /> },
                { path: "push", element: <PushAlertPage /> },
                {
                  path: "term",
                  children: [
                    { path: "", element: <TermPage /> },
                    { path: "service", element: <ServiceTermPage /> },
                    { path: "personalInfo", element: <PersonalInfoTermPage /> },
                    { path: "participant", element: <ParticipantTermPage /> },
                    { path: "marketing", element: <MarketingTermPage /> },
                  ],
                },
                { path: "withdraw", element: <WithdrawPage /> },
              ],
            },
          ],
        },
        {
          path: "funding",
          children: [
            { path: "", element: <FundingPage /> },
            { path: "register1", element: <FundingRegister1Page /> },
            { path: "register1/sub", element: <FundingRegister1SubPage /> },
            { path: "register2", element: <FundingRegister2Page /> },
            { path: "register3", element: <FundingRegister3Page /> },
            { path: "register4", element: <FundingRegister4Page /> },
            { path: ":id", element: <FundingDetailPage /> },
          ],
        },
        {
          path: "pig",
          children: [
            { path: "", element: <PigPage /> },
            { path: "register", element: <PigRegisterPage /> },
            { path: ":id", element: <PigDetailPage /> },
          ],
        },
        {
          path: "farm",
          children: [
            { path: "", element: <FarmPage /> },
            { path: "register", element: <FarmRegisterPage /> },
            { path: "verify", element: <FarmVerifyBizPage /> },
            { path: ":id", element: <FarmDetailPage /> },
            { path: "cctv", element: <FarmCCTV /> },
            { path: "unity", element: <FarmUnity /> },
          ],
        },
        {
          path: "store",
          children: [
            { path: "", element: <StorePage /> },
            { path: "order", element: <StoreOrderPage /> },
            { path: "order/:id", element: <StoreOrderDetailPage /> },
            { path: "delivery", element: <StoreDeliveryPage /> },
            { path: "delivery/:id", element: <StoreDeliveryDetailPage /> },
            { path: "return", element: <StoreReturnPage /> },
            { path: "return/:id", element: <StoreReturnDetailPage /> },
            { path: "feedback", element: <StoreFeedbackPage /> },
          ],
        },
        // { path: 'alert', element: <AlertPage /> },
        // { path: 'setting',
        //     element: <SettingPage />,
        //     children: [
        //         { path: 'introduce', element: <IntroducePage /> },
        //         { path: 'push-alert', element: <PushAlertPage /> },
        //     ]
        // },

        /// 잠시주석
        // {
        //   path: "publicapi",
        //   children: [{ path: "bizenroll", element: <BizenrollPage /> }],
        // },
      ],
    },

    // 환경설정
  ]);
}
